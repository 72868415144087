<template>
  <div>
    <div class="maintitle">类型管理</div>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-select v-model="crntprovince" @change="slctprovince" placeholder="请选择省份">
          <el-option
            v-for="(item,index) in province"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="crntcity" placeholder="请选择城市" @change="slctcity">
          <el-option
            v-for="(item,index) in city"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="crntdistrict" placeholder="请选择县/区">
          <el-option
            v-for="(item,index) in district"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="getlist">查找</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="list"
      row-key="id"
      border
      lazy
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="type" label="类型" width="180"></el-table-column>
      <el-table-column prop="status_text" label="状态" width="100" align="center"></el-table-column>
      <el-table-column prop="price" label="价格(元)" width="100" align="center"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button-group v-if="scope.row.pid != 0">
            <el-button
              size="small"
              @click="changestatus(scope)"
              v-if="scope.row.status == 1"
              type="danger"
            >停用</el-button>
            <el-button size="small" @click="changestatus(scope)" v-else type="success">启用</el-button>
            <el-button size="small" @click="mdfy(scope)" type="primary">修改</el-button>
          </el-button-group>
          <el-button-group v-else>
            <el-button size="small" @click="addtype(scope)">添加子类</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="addTypeDiv">
      <el-form :model="mdfytype" :rules="rules" ref="mdfytype">
        <el-form-item
          label="大分类"
          v-if="act == 'add'"
          :label-width="formLabelWidth"
          class="text-left"
        >{{crntPTypeName}}</el-form-item>
        <el-form-item label="名称" :label-width="formLabelWidth" prop="type">
          <el-input v-model="mdfytype.type" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="回收价格" :label-width="formLabelWidth" prop="price">
          <el-input v-model="mdfytype.price" autocomplete="off" type="number"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addTypeDiv = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //类型列表
      addTypeDiv: false, //添加类型窗口
      mdfytype: {
        type: "", //编辑的子类型名称
        price: 0, //子类型价格
        pid: 0, //子类型的父类型
        id: 0,
        district:0
      },
      crntPTypeName: "", //子类型的父类型名称
      act: "add",
      formLabelWidth: "150px",
      crntscope: [], //当前编辑对象
      rules: {
        type: [
          { required: true, message: "请输入子分类名称", trigger: "blur" },
          { min: 2, message: "长度不能小于两个汉字", trigger: "blur" }
        ],
        price: [{ required: true, message: "请输入回收价格", trigger: "blur" }]
      },
      crntprovince: "", //当前选择省份
      crntcity: "", //当前选择地区
      crntdistrict: "", //当前选择县/区
      province: [], //省份列表
      city: [], //地区列表
      district: [] //县/区列表
    };
  },
  computed: {},
  methods: {
    getlist: function() {
      this.$axios({
        url: this.HOST + "/admin/goodstype/getTypeList",
        method: "post",
        data: {
          district:this.crntdistrict
        }
      }).then(res => {
        let temp = [];
        for (var i in res.data) {
          temp.push(res.data[i]);
        }
        this.list = temp;
      });
    },
    changestatus(scope) {
      let status = scope.row.status;
      status = status == 1 ? 2 : 1;
      let str = status == 1 ? "启用" : "停用";
      this.$confirm("确定要" + str + scope.row.type + "吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let param = {
            id: scope.row.id,
            status: status
          };
          this.$axios({
            method: "post",
            url: this.HOST + "/admin/goodstype/setGoodsTypeStatus",
            data: param
          }).then(res => {
            if (res.data.status == 1) {
              scope.row.status = status;
              scope.row.status_text = status == 1 ? "启用" : "已禁用";
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    add() {
      this.mdfytype.district = this.crntdistrict;
      this.$axios({
        method: "post",
        url: this.HOST + "/admin/goodstype/addtype",
        data: {
          info: this.mdfytype
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.addTypeDiv = false;
          if (this.act == "add") {
            this.getlist();
          } else {
            this.crntscope.price = this.mdfytype.price;
            this.crntscope.type = this.mdfytype.type;
          }
        }
      });
    },
    addtype(scope) {
      if (this.$refs.mdfytype !== undefined) {
        this.$refs.mdfytype.resetFields();
      }
      this.mdfytype.type = "";
      this.mdfytype.pid = scope.row.id;
      this.crntPTypeName = scope.row.type;
      this.mdfytype.price = 0;
      this.mdfytype.id = 0;
      this.addTypeDiv = true;
      this.act = "add";
    },
    mdfy(scope) {
      if (this.$refs.mdfytype !== undefined) {
        this.$refs.mdfytype.resetFields();
      }
      this.crntscope = scope.row;
      this.mdfytype.type = scope.row.type;
      this.mdfytype.pid = scope.row.pid;
      this.mdfytype.price = scope.row.price;
      this.mdfytype.id = scope.row.id;
      this.addTypeDiv = true;
      this.act = "mdfy";
    },
    slctprovince() {
      this.getAreaList(this.crntprovince, "c");
      this.crntcity = "";
      this.crntdistrict = "";
    },
    slctcity() {
      this.getAreaList(this.crntcity, "co");
    },
    getAreaList(id, str) {
      let url = this.HOST + "/admin/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {
          id: id
        }
      }).then(res => {
        if (res.data.status == 1) {
          switch (str) {
            case "p":
              this.province = res.data.list;
              this.city = [];
              this.district = [];
              this.crntprovince = "";
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "c":
              this.city = res.data.list;
              this.district = [];
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "co":
              this.district = res.data.list;
              this.crntdistrict = "";
              break;
          }
        } else {
          return [];
        }
      });
    },
  },
  created: function() {
    this.getAreaList(0,'p');
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>