<template>
  <div>
    <div class="maintitle">回收哥位置</div>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-select v-model="crntprovince" @change="slctprovince" placeholder="请选择省份">
          <el-option
            v-for="(item,index) in province"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="crntcity" placeholder="请选择城市" @change="slctcity">
          <el-option
            v-for="(item,index) in city"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="crntdistrict" placeholder="请选择县/区" @change="setcenter">
          <el-option
            v-for="(item,index) in district"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="getlist">查找</el-button>
      </el-col>
    </el-row>
    <div id="container">
      <el-amap class="amap-box" :center="center" :zoom="zoom">
        <!-- <el-amap-marker vid="component-marker" :position="componentMarker.position" :content-render="componentMarker.contentRender" ></el-amap-marker> -->
        <el-amap-marker
          v-for="(marker, index) in list"
          :position="position(marker.location)"
          :key="index"
          :title="marker.name"
        ></el-amap-marker>
      </el-amap>
    </div>
  </div>
</template>

<script>
import VueAMap from "vue-amap";
import Vue from "vue";
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "f8797ef1e0fd8d201e7470ae64a466ad",
  plugin: [
    "AMap.PolyEditor" //编辑 折线多，边形
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4"
});
export default {
  data() {
    return {
      list: [],
      crntprovince: "", //当前选择省份
      crntcity: "", //当前选择地区
      crntdistrict: "", //当前选择县/区
      province: [], //省份列表
      city: [], //地区列表
      district: [], //县/区列表
      //地图相关
      zoom: 12,
      center: [118.487893, 36.686229],
      show: false,
      pas: "",
      clean: "",
      date: "",
      search: "",
      mixed: "",
      stat1: "",
      restaurants: [],
      maxlength: "",
      num: 1
    };
  },
  methods: {
    getlist: function() {
      this.$axios({
        url: this.HOST + "/admin/huishouge/getLocation",
        method: "post",
        data: {
          district: this.crntdistrict
        }
      }).then(res => {
        this.list = res.data;
      });
    },
    slctprovince() {
      this.getAreaList(this.crntprovince, "c");
      this.crntcity = "";
      this.crntdistrict = "";
    },
    slctcity() {
      this.getAreaList(this.crntcity, "co");
    },
    getAreaList(id, str) {
      let url = this.HOST + "/admin/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {
          id: id
        }
      }).then(res => {
        if (res.data.status == 1) {
          switch (str) {
            case "p":
              this.province = res.data.list;
              this.city = [];
              this.district = [];
              this.crntprovince = "";
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "c":
              this.city = res.data.list;
              this.district = [];
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "co":
              this.district = res.data.list;
              this.crntdistrict = "";
              break;
          }
        } else {
          return [];
        }
      });
    },
    handlemyMapFn() {
      VueAMap.initAMapApiLoader({
        key: "f8797ef1e0fd8d201e7470ae64a466ad",
        plugin: [
          "AMap.PolyEditor" //编辑 折线多，边形
        ],
        // 默认高德 sdk 版本为 1.4.4
        v: "1.4.4"
      });
    },
    position(str) {
      if (str) {
        let p = JSON.parse(str);
        return [parseFloat(p.lng), parseFloat(p.lat)];
      } else {
        return [0, 0];
      }
    },
    setcenter(e) {
      for (var i in this.district) {
        if (this.district[i].id == e) {
          let p = this.district[i].location;
          this.center = [parseFloat(p.lng), parseFloat(p.lat)];
          break;
        }
      }
    }
  },
  computed: {},
  created: function() {
    this.getAreaList(0, "p");
  }
};
</script>
<style>
#container {
  width: 100%;
  height: 600px;
}
</style>