<template>
  <div>
    <div class="maintitle">
      <span v-if="form.uuid==0">添加</span>
      <span v-else>修改</span>操作员
    </div>
    <el-divider></el-divider>
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="账号" prop="account">
        <el-input v-model="form.account" maxlength="15" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name" maxlength="4" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="tel">
        <el-input v-model="form.tel" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="状态" class="text-left">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="2">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="权限列表">
        <el-checkbox-group v-model="form.quanxian" class="text-left">
          <el-checkbox v-for="(item,index) in quanxianlist" :key="index" :label="item.id+'-'+item.title" :value="item.id">{{item.title}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <el-button @click="add">
      <span v-if="form.uuid==0">添加</span>
      <span v-else>修改</span>
    </el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        uuid: "", //用户主键
        name: "", //用户姓名
        tel: "", //用户联系电话
        account: "", //用户账号
        quanxian:[],
        status: 1 //用户状态
      },
      quanxianlist:[],
      rules: {
        name: [
          { required: true, message: "请输入操作员姓名", trigger: "blur" },
          { min: 2, max: 4, message: "长度在 2 到 4 个汉字", trigger: "blur" }
        ],
        account: [
          { required: true, message: "请输入操作员账号", trigger: "blur" },
          { min: 6, max: 15, message: "6~15位字母、数字", trigger: "blur" }
        ],
        tel:[
          {required:true,message:"请输入联系电话",trigger:'blur'},
          {min:11,max:11,message:"联系电话格式不正确",trigger:'blur'}
        ],
      },
    };
  },
  methods: {
    getinfo: function() {
      let id = this.$route.params.id;
      if (!id) {
        return;
      }
      this.$axios({
        url: this.HOST + "/admin/operator/getinfo",
        method: "post",
        data: { id: id }
      }).then(res => {
        this.form = res.data
      });
    },
    getquanxianlist:function(){
      this.$axios({
        method:'post',
        url:this.HOST + "/admin/operator/getquanxianlist",
        data:{}
      }).then(res=>{ 
        this.quanxianlist = res.data;
      });
    },
    add: function() {
      this.$axios({
        url: this.HOST + "/admin/operator/add",
        method: "post",
        data: {
          info: this.form
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.$router.push("/operator");
        }
      })
    },
  }, 
  computed: {},
  created: function() {
    this.getinfo();
    this.getquanxianlist();
    // this.getcommonlist();
  }
};
</script>

<style>
</style>