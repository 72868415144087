<template>
  <div>
    <div class="maintitle">订单列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-select
        v-model="crntprovince"
        @change="slctprovince"
        placeholder="请选择省份"
        style="width:120px;"
      >
        <el-option
          v-for="(item,index) in province"
          :key="index"
          :label="item.fullname"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select v-model="crntcity" placeholder="请选择城市" @change="slctcity" style="width:120px;">
        <el-option
          v-for="(item,index) in city"
          :key="index"
          :label="item.fullname"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select v-model="crntdistrict" placeholder="请选择县/区" style="width:120px;">
        <el-option
          v-for="(item,index) in district"
          :key="index"
          :label="item.fullname"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="timerange"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
        style="width:250px;"
        class="margin-big-left"
      ></el-date-picker>
      <el-button type="primary" @click="srch('diy')" class="margin-left">搜索</el-button>
      <el-button type="primary" @click="srch('today')" class="margin-left">今天</el-button>
      <el-button type="primary" @click="srch('yesterday')" class="margin-left">昨天</el-button>
      <el-button type="primary" @click="srch('7day')" class="margin-left">最近7天</el-button>
    </el-row>
    <el-table :data="list" border>
      <el-table-column fixed prop="ordersn" label="订单编号" width="180"></el-table-column>
      <el-table-column prop="contact" label="联系人" width="120"></el-table-column>
      <el-table-column prop="tel" label="手机号" width="120"></el-table-column>
      <el-table-column prop="goodstype" label="货物类型" width="120"></el-table-column>
      <el-table-column prop="yuguzhongliang" label="预计重量" width="120"></el-table-column>
      <el-table-column prop="address" label="地址"></el-table-column>
      <el-table-column prop="hsgname" label="回收人员" width="120"></el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button-group>
            <el-button @click="handleClick(scope)" type="primary" size="small">查看</el-button>
          <!-- <el-button v-if="scope.row.status == 1" @click="repush(scope)" type="primary" size="small">重新推送</el-button> -->
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //合同列表
      province: [],
      city: [],
      district: [],
      crntprovince: "",
      crntcity: "",
      crntdistrict: "",
      page: 1, //当前页数
      pagesize: this.pagesize, //当前每页条数
      pagesizes: this.pagesizes, //每页条数选择项列表
      total: 0, //信息总数（所有页）
      timerange: [this.getdate("start"), this.getdate("end")], //查找时间范围，默认为本月
      srchtype:'today',//搜索时间范围
    };
  },
  mounted() {},
  methods: {
    handleClick(scope) {
      this.$router.push("/order/orderDetail/" + scope.row.ordersn);
    },
    getlist() {
      let param = {
        pagesize: this.pagesize,
        page: this.page,
        area: this.crntdistrict,
        srchtype:this.srchtype
      };
      this.$axios({
        url: this.HOST + "/admin/order/getList",
        method: "post",
        data: param
      }).then(res => {
        this.list = res.data.list.data;
        this.total = res.data.list.total;
      });
    },
    getAreaList(id, str) {
      let url = this.HOST + "/admin/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {
          id: id
        }
      }).then(res => {
        if (res.data.status == 1) {
          switch (str) {
            case "p":
              this.province = res.data.list;
              this.city = [];
              this.district = [];
              this.crntprovince = "";
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "c":
              this.city = res.data.list;
              this.district = [];
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "co":
              this.district = res.data.list;
              this.crntdistrict = "";
              break;
          }
        } else {
          return [];
        }
      });
    },
    slctprovince() {
      this.getAreaList(this.crntprovince, "c");
    },
    slctcity() {
      this.getAreaList(this.crntcity, "co");
    },
    srch(str) {
      this.srchtype = str=='diy'?this.timerange:str;
      this.page = 1;
      this.getlist();
    },
    getdate(str) {
      let date = new Date();
      if (str == "start") {
        return (
          date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + "01 00:00:00"
        );
      } else {
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " 23:59:59"
        );
      }
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    repush(scope){
      this.$axios({
        method:'post',
        url:this.HOST + '/admin/order/pushOrder',
        data:{
          id:scope.row.ordersn
        }
      }).then(res=>{
        if(res.data.status == 1){
          this.$message({
            type:'info',
            message:"推送成功"
          })
        }
      });
    }
  },
  created: function() {
    this.getlist();
    this.getAreaList(0, "p");
  }
};
</script>

<style>
</style>
