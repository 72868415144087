<template>
  <div>
    <div class="maintitle">操作员列表</div>
    <el-divider></el-divider>
    <div class="text-left margin-bottom">
      <el-button type="primary" @click="add">添加操作员</el-button>
    </div>
    <el-table :data="list" border>
      <el-table-column align="center" prop="name" label="姓名"></el-table-column>
      <el-table-column align="center" prop="tel" label="联系电话"></el-table-column>
      <el-table-column align="center" prop="quanxian_text" label="权限"></el-table-column>
      <el-table-column align="center" prop="lasttime" label="最后登录时间"></el-table-column>
      <el-table-column align="center" prop="lastip" label="最后登录ip"></el-table-column>
      <el-table-column align="center" prop="status_text" label="状态"></el-table-column>
      <el-table-column align="center" type="index" fixed="right" label="操作" width="300">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="changestatus(scope)" v-if="scope.row.status==1">禁用</el-button>
          <el-button type="success" size="small" @click="changestatus(scope)" v-else>启用</el-button>
          <el-button type="primary" size="small" @click="resetpassword(scope)">重置密码</el-button>
          <el-button type="primary" size="small" @click="mdfy(scope)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [] //业务人员列表
    };
  },
  computed: {},
  methods: {
    getlist: function() {
      let that = this;
      this.$axios({
        url: this.HOST + "/admin/operator/getList",
        method: "post",
        data: {}
      }).then(res => {
        this.list = res.data;
      });
    },
    changestatus(scope) {
      let status = scope.row.status == 1 ? 2 : 1;
      let id = scope.row.uuid;
      let status_text = status == 2 ? "已禁用" : "正常";
      let statustext = status == 2 ? "禁用" : "启用";
      let that = this;
      let level1 = false;
      let str = "确定将" + scope.row.name + statustext + "吗？";
      this.$confirm(str, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          that
            .$axios({
              url: that.HOST + "/admin/operator/changstatus",
              method: "post",
              data: { id: id, status: status }
            })
            .then(function(res) {
              if (res.data.status == 1) {
                scope.row.status_text = status_text;
                scope.row.status = status;
              }
            }),
            error => {
              that.$alert("操作失败", "温馨提示");
            };
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    mdfy(scope) {
      this.$router.push("/operator/mdfy/" + scope.row.uuid);
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    add() {
      this.$router.push("/operator/add");
    },
    resetpassword(scope) {
      let uuid = scope.row.uuid;
      this.$axios({
        method: "post",
        url: this.HOST + "/admin/operator/resetpassword",
        data: { id: uuid }
      }).then(res => {
        if (res.data.status == 1) {
          this.$alert(
            scope.row.name + "的密码已经修改为" + res.data.info, 
            "修改成功"
          );
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info
          });
        }
      });
    }
  },
  created: function() {
    this.getlist();
  }
};
</script>

<style>
</style>