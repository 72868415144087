<template>
  <div>
    <div class="maintitle">banner图列表</div>
    <el-divider></el-divider>
    <div class="text-left margin-bottom">
      <el-button type="primary" @click="addBannerDiv = true">添加banner图</el-button>
    </div>
    <el-table :data="list" style="width: 100%;margin-bottom: 20px;" row-key="id" border stripe>
      <el-table-column align="center" label="图片">
        <template slot-scope="scope">
          <img :src="HOST+scope.row.pic" alt />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="url" label="链接"></el-table-column>
      <el-table-column align="center" prop="sort" label="排序" width="80"></el-table-column>
      <el-table-column align="center" type="index" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button-group>
            <el-button @click="mdfysort(scope)">修改排序</el-button>
            <el-button @click="deletebanner(scope)">删除</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加banner图" :visible.sync="addBannerDiv">
      <el-form
        :model="banner"
        label-width="80px"
        style="width:500px;margin:10px auto;"
        ref="banner"
      >
        <el-form-item label="图片" prop="pic">
          <el-upload
            drag
            :action="HOST+'/admin/Banner/addBannerPic'"
            :data="authdata"
            accept="jpg"
            :on-success="showresult"
            :show-file-list="false"
            v-if="banner.pic==''"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">只能上传jpg文件，且不超过500kb</div>
          </el-upload>
          <img v-else :src="HOST+'/storage/banner/'+banner.pic" style="width:100%;" />
        </el-form-item>
        <el-form-item label="链接地址" prop="url">
          <el-input type="text" v-model="banner.url" placeholder="请输入链接地址"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input type="number" v-model="banner.sort" min="0" placeholder="请输入排序"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelup">取 消</el-button>
        <el-button type="primary" @click="upbanner">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改排序" :visible.sync="mdfysortdiv">
      <el-form label-width="80px" style="width:500px;margin:10px auto;">
        <el-form-item label="排序" prop="sort">
          <el-input type="number" v-model="newsort" min="0"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="mdfysortdiv=false">取 消</el-button>
        <el-button type="primary" @click="mdfyact">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      addBannerDiv: false,
      banner: {
        pic: "",
        url: "",
        sort: 0
      },
      authdata: {
        admin_id: sessionStorage.getItem("admin_id"),
        admin_token: sessionStorage.getItem("admin_token")
      },
      mdfysortdiv: false,
      newsort: 0,
      crntscope: []
    };
  },
  computed: {},
  methods: {
    getlist: function() {
      let that = this;
      this.$axios({
        url: this.HOST + "/admin/Banner/getBannerList",
        method: "post",
        data: {}
      }).then(res => {
        this.list = res.data.list;
      });
    },
    mdfysort: function(scope) {
      this.newsort = scope.row.sort;
      this.crntbannerid = scope.row.id;
      this.mdfysortdiv = true;
      this.crntscope = scope;
    },
    mdfyact() {
      this.$axios({
        url: this.HOST + "/admin/banner/mdfysoft",
        method: "post",
        data: { 
          id: this.crntbannerid,
          sort:this.newsort
          }
      }).then(res => {
        this.mdfysortdiv = false;
        this.crntscope.row.sort = this.newsort;
      });
    },
    upbanner() {
      this.$axios({
        url: this.HOST + "/admin/banner/addBanner",
        method: "post",
        data: this.banner
      }).then(res => {
        this.getlist();
        this.addBannerDiv = false;
        if (this.$refs.banner !== undefined) {
          this.$refs.banner.resetFields();
        }
      });
    },
    numbeyond() {
      this.$message({
        type: "error",
        message: "只能上传一张图片"
      });
    },
    showresult(res) {
      if (res.status == 1) {
        this.banner.pic = res.savename;
      } else if (res.status == 0) {
        this.$message({
          type: "error",
          message: res.info
        });
      }
    },
    cancelup() {
      if (this.banner.pic) {
        this.$axios({
          url: this.HOST + "/admin/Banner/delpic",
          method: "post",
          data: { pic: this.banner.pic }
        }).then(res => {});
      }
      if (this.$refs.banner !== undefined) {
        this.$refs.banner.resetFields();
      }
      this.addBannerDiv = false;
    },
    deletebanner(scope) {
      this.$confirm("确定删除该banner图吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$axios({
            url: this.HOST + "/admin/banner/deletebanner",
            method: "post",
            data: { id: scope.row.id }
          }).then(res => {
            if (res.data.status == 1) {
              this.list.splice(scope.$index, 1);
            }
          }),
            error => {
              this.$alert("操作失败", "温馨提示");
            };
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    }
  },
  created: function() {
    this.getlist();
  }
};
</script>

<style>
</style>