<template>
  <div>
    <div class="maintitle">运营商列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
        <el-select v-model="crntprovince" @change="slctprovince" placeholder="请选择省份">
          <el-option
            v-for="(item,index) in province"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select v-model="crntcity" placeholder="请选择城市" @change="slctcity" v-if='jiamengtype==2'>
          <el-option
            v-for="(item,index) in city"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select v-model="crntdistrict" placeholder="请选择县/区" v-if='jiamengtype==2'>
          <el-option
            v-for="(item,index) in district"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select v-model="crntdistrict" placeholder="请选择城市" v-if='jiamengtype==1'>
          <el-option
            v-for="(item,index) in city"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-radio-group v-model="jiamengtype" class="margin-left" @change='resetdata'>
          <el-radio-button label="1">地级代理</el-radio-button>
          <el-radio-button label="2">县级代理</el-radio-button>
        </el-radio-group>
        <el-button type="primary" @click="srch" class="margin-left">搜索</el-button>
        <el-button type="success" @click="addjiameng" class="margin-left">添加运营商</el-button>
    </el-row>

    <el-table :data="list" border>
      <el-table-column align="center" fixed prop="name" label="姓名" width="150"></el-table-column>
      <el-table-column align="center" fixed prop="tel" label="手机号" width="150"></el-table-column>
      <el-table-column align="center" prop="area_text" label="代理地区" min-width="200"></el-table-column>
      <el-table-column align="center" prop="addtime" label="添加时间" width="200"></el-table-column>
      <el-table-column align="center" prop="logintime" label="最后登录时间" width="200"></el-table-column>
      <el-table-column align="center" prop="lastip" label="最后登录IP" width="200"></el-table-column>
      <el-table-column align="center" prop="status_text" fixed="right" label="状态" width="80"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="240">
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="small" @click="mdfy(scope)">修改</el-button>
            <el-button size="small" @click="resetpassword(scope)">重置密码</el-button>
            <el-button
              size="small"
              @click="$router.push('/jiameng/transaction/'+scope.row.uuid)"
            >查看流水</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      list: [], //合同列表
      page: 1, //当前页数
      pagesize: this.pagesize, //当前每页条数
      pagesizes: this.pagesizes, //每页条数选择项列表
      total: 0, //信息总数（所有页）
      crntprovince: "", //当前选择省份
      crntcity: "", //当前选择地区
      crntdistrict: "", //当前选择县/区
      province: [], //省份列表
      city: [], //地区列表
      district: [], //县/区列表
      jiamengtype:2,//运营商类型
    };
  },
  computed: {},
  methods: {
    resetdata:function(){ //更改运营商类型时重置数据
      this.crntcity = '';
      this.crntdistrict = '';
    },
    getlist: function() {
      let that = this;
      let param = {
        pagesize: this.pagesize,
        page: this.page,
        type:this.jiamengtype,
      };
      if (this.crntdistrict) {
        param.area = this.crntdistrict;
      } else {
        param.area = "";
      }
      this.$axios({
        url: this.HOST + "/admin/jiameng/getList",
        method: "post",
        data: param
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    getcommonlist: function() {
      let param = ["type"];
      this.$axios({
        url: this.HOST + "/admin/index/getcommonlist",
        method: "post",
        data: { list: param }
      }).then(res => {
        this.dep = res.data.dep;
        this.type = res.data.type;
        this.yewuxianlist = res.data.yewuxian;
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    slctprovince() {
      this.getAreaList(this.crntprovince, "c");
      this.crntcity = "";
      this.crntdistrict = "";
    },
    slctcity() {
      this.getAreaList(this.crntcity, "co");
    },
    getAreaList(id, str) {
      let url = this.HOST + "/admin/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {
          id: id
        }
      }).then(res => {
        if (res.data.status == 1) {
          switch (str) {
            case "p":
              this.province = res.data.list;
              this.city = [];
              this.district = [];
              this.crntprovince = "";
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "c":
              this.city = res.data.list;
              this.district = [];
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "co":
              this.district = res.data.list;
              this.crntdistrict = "";
              break;
          }
        } else {
          return [];
        }
      });
    },
    mdfy(scope) {
      this.$router.push("/jiameng/mdfy/" + scope.row.uuid);
    },
    addjiameng() {
      this.$router.push("/jiameng/add");
    },
    resetpassword(scope) {
      let uuid = scope.row.uuid;
      this.$axios({
        method: "post",
        url: this.HOST + "/admin/jiameng/resetpassword",
        data: { id: uuid }
      }).then(res => {
        if (res.data.status == 1) {
          this.$alert(
            scope.row.name + "的密码已经修改为" + res.data.info,
            "修改成功"
          );
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info
          });
        }
      });
    }
  },
  created: function() {
    this.getlist();
    this.getAreaList(0, "p");
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>