<template>
  <div style="max-width:1000px;">
    <div class="maintitle">
      <span v-if="form.uuid==0">添加</span>
      <span v-else>修改</span>运营商
    </div>
    <el-divider></el-divider>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="form.tel" maxlength="11" minlength="11"></el-input>
      </el-form-item>
      <el-form-item label="状态" class="text-left">
        <el-radio-group v-model="form.status">
          <el-radio-button :label="1">启用</el-radio-button>
          <el-radio-button :label="2">禁用</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态" class="text-left">
        <el-radio-group v-model="form.level" @change="form.arealist=[]">
          <el-radio-button :label="1">地级代理</el-radio-button>
          <el-radio-button :label="2">县级代理</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="代理地区" class="text-left" v-if='form.status==1'>
        <el-select
          v-model="crntprovince"
          @change="slctprovince"
          placeholder="请选择省份"
          class="margin-right"
        >
          <el-option
            v-for="(item,index) in province"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select v-model="crntcity" placeholder="请选择城市" @change="slctcity" v-if="form.level==2">
          <el-option
            v-for="(item,index) in city"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-checkbox-group v-model="form.arealist" v-if="form.level==1">
          <el-checkbox
            v-for="(item,index) in city"
            :key="index"
            :label="item.id+'-'+item.fullname+'-'+JSON.stringify(item.location)"
            :value="item.id"
          >{{item.fullname}}</el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group v-model="form.arealist" v-if="form.level==2">
          <el-checkbox
            v-for="(item,index) in district"
            :key="index"
            :label="item.id+'-'+item.fullname+'-'+JSON.stringify(item.location)"
            :value="item.id"
          >{{item.fullname}}</el-checkbox>
        </el-checkbox-group>
        <div>
          <span
            v-for="(item,index) in form.arealist"
            :key="index"
            class="margin-right"
            @click="delarea(item)"
          >{{item.split('-')[1]}}</span>
        </div>
      </el-form-item>
    </el-form>
    <el-button @click="add">
      <span v-if="form.uuid==0">添加</span>
      <span v-else>修改</span>
    </el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        uuid: '', //加盟用户主键
        name: "", //加盟用户姓名
        tel: "", //加盟用户联系电话
        arealist: [], //用户加盟地区列表
        status: 1, //用户状态
        level:2,
      },
      province: [], //省份列表
      city: [], //地区列表
      district: [], //县、区列表
      crntprovince: "", //当前选择省份
      crntcity: "" //当前选择地区
    };
  },
  methods: {
    getinfo: function() {
      let id = this.$route.params.id;
      if (!id) {
        return;
      }
      this.$axios({
        url: this.HOST + "/admin/jiameng/getinfo",
        method: "post",
        data: { id: id }
      }).then(res => {
          this.form = res.data;
      });
    },
    getcommonlist: function() {
      let param = ["dep", "type", "yewuxian"];
      this.$axios({
        url: this.HOST + "/admin/index/getcommonlist",
        method: "post",
        data: { list: param }
      }).then(res => {
        this.dep = res.data.dep;
        this.type = res.data.type;
        this.yewuxianlist = res.data.yewuxian;
      });
    },
    add: function() { 
      this.$axios({
        url: this.HOST + "/admin/jiameng/add",
        method: "post",
        data: {
          info: this.form
        }
      }).then(res => {
        if(res.data.status == 1){
          this.$router.push("/jiameng");
        }else if (res.data.status == 0) {
          this.$message({
            type:'error',
            message:res.data.info
          });
        }
      });
    },
    getAreaList: function(id, str) {
      let url = this.HOST + "/admin/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {
          id: id
        }
      }).then(res => {
        if (res.data.status == 1) {
          switch (str) {
            case "p":
              this.province = res.data.list;
              this.city = [];
              this.district = [];
              this.crntprovince = "";
              this.crntcity = "";
              break;
            case "c":
              this.city = res.data.list;
              this.district = [];
              this.crntcity = "";
              break;
            case "co":
              this.district = res.data.list;
              break;
          }
        } else {
          return [];
        }
      });
    },
    slctprovince() {
      this.getAreaList(this.crntprovince, "c");
      this.crntcity = "";
      this.crntdistrict = "";
    },
    slctcity() {
      this.getAreaList(this.crntcity, "co");
    },
    delarea(str) {
      this.$confirm("确定要删除" + str.split("-")[1] + "吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let i = this.form.arealist.indexOf(str);
          this.form.arealist.splice(i, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    }
  },
  computed: {},
  created: function() {
    this.getinfo();
    // this.getcommonlist();
    this.getAreaList("0", "p");
  }
};
</script>

<style>
</style>