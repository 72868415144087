<template>
  <div style="max-width:1000px;">
    <div class="maintitle">提现管理</div>
    <el-divider></el-divider>
    <el-table :data="list" border>
      <el-table-column align="center" fixed prop="name" label="姓名" width="150"></el-table-column>
      <el-table-column align="center" fixed prop="tel" label="手机号" width="150"></el-table-column>
      <el-table-column align="center" prop="total" label="提现金额" min-width="200"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="400">
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="small" @click="cfrm(scope)" v-if="scope.row.status == 0">完成</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      total: 0
    };
  },
  methods: {
    getlist: function() {
      this.$axios({
        url: this.HOST + "/admin/jiameng/gettixian",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    cfrm(scope) {
      this.$confirm("确定已经付款给" + scope.row.name + "了吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$axios({
            method: "post",
            url: this.HOST + "/admin/jiameng/cfrmtixian",
            data: {
              id: scope.row.id
            }
          }).then(res => {
            if (res.data.status == 1) {
              this.$message({
                type: "info",
                message: "确认提现完成"
              });
              scope.row.status = 1;
            } else {
              this.$message({
                type: "error",
                message: res.data.info
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    }
  },
  computed: {},
  created: function() {
    this.getlist();
  }
};
</script>
