<template>
  <div>
    <div class="maintitle">系统设置</div>
    <el-divider></el-divider>
    <el-form style="width:500px">
        <el-form-item v-for="(item,index) in list" :key="index" :label="item.title">
            <el-input v-model='item.detail' @change="mdfy(index)"></el-input>
        </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: []
    };
  },
  methods: {
    getlist: function() {
      this.$axios({
        url: this.HOST + "/admin/setting/getList",
        method: "post",
        data: {}
      }).then(res => {
          if(res.data.status == 1){
              this.list = res.data.list;
          }
      });
    },
    mdfy(index){
        this.$axios({
        url: this.HOST + "/admin/setting/mdfy",
        method: "post",
        data: {id:this.list[index].id,detail:this.list[index].detail}
      }).then(res => {
          if(res.data.status == 1){
              this.$message({
                  type:'info',
                  message:"修改成功"
              });
          }else{
              this.$message({
                  type:'info',
                  message:"修改失败"
              });
              this.getlist();
          }
      });
    }
  },
  created: function() {
    this.getlist();
  }
};
</script>