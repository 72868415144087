<template>
  <div>
    <div id="myChart" ref="myChart"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      pas: "",
      clean: "",
      date: "",
      search: "",
      mixed: "",
      stat1: "",
      restaurants: [],
      maxlength: "",
      num: 1
    };
  },
  methods: {
    
  },
  mounted() {
    // 调用绘制图表的方法
    this.draw();
  }
};
</script>