<template>
  <div>
    <div class="maintitle">所有留言</div>
    <el-divider></el-divider>
    <el-table
      :data="list"
      row-key="id"
      border
      stripe
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column align="center" prop="name" label="昵称" width="200px"></el-table-column>
      <el-table-column align="center" prop="tel" label="联系电话" width='150px'></el-table-column>
      <el-table-column align="center" prop="detail" label="留言内容"></el-table-column>
      <el-table-column align="center" prop="addtime" label="留言时间" width="180px"></el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //业务人员列表

      page: 1, //当前页数
      total: 0 //信息总条数
    };
  },
  computed: {},
  methods: {
    getlist: function() {
      this.$axios({
        url: this.HOST + "/admin/feedback/getAllFeedbackList",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize
        }
      }).then(res => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    srch() {
      this.page = 1;
      this.getlist();
    }
  },
  created: function() {
    this.getlist();
  }
};
</script>

<style>
</style>