<template>
  <div>
    <div class="maintitle">运营商交易流水</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-col :span="18">
        <el-date-picker
          v-model="timerange"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          style="width:250px;"
          class="margin-big-left"
        ></el-date-picker>
        <el-select v-model="servicetype" class="margin-left" placeholder="请选择业务类型">
          <el-option value="2" label="家政"></el-option>
          <el-option value="1" label="回收"></el-option>
        </el-select>
        <el-select v-model="type" @change="changeType=true" class="margin-left">
          <el-option value="all" label="全部"></el-option>
          <el-option value="chongzhi" :label="servicetype==1?'充值':'服务收入'"></el-option>
          <el-option value="tixian" label="提现"></el-option>
        </el-select>
        <el-button class="margin-left" @click="getlist">查询</el-button>
      </el-col>
      <el-col :span="6">
        <span>
          总计：
          <span
            style="font-weight:bold;"
            :class="totalmoney>0?'text-success':'text-danger'"
          >{{totalmoney}}元</span>
        </span>
      </el-col>
    </el-row>

    <el-table :data="list" border>
      <el-table-column align="center" fixed prop="ordersn" label="订单号"></el-table-column>
      <el-table-column align="center" label="金额">
        <template slot-scope="scope">
          <span :class="scope.row.total>0?'text-success':'text-danger'">{{scope.row.total}}元</span>
        </template>
      </el-table-column>
      <el-table-column sortable align="center" prop="addtime" label="时间"></el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      list: [],
      page: 1, //当前页数
      total: 0, //信息总数（所有页）
      timerange: [this.getdate("start"), this.getdate("end")], //查找时间范围，默认为本月
      totalmoney: 0,
      type: "all",
      changeType: false,
      servicetype:''
    };
  },
  computed: {},
  methods: {
    getlist: function() {
      if (this.changeType) {
        this.page = 1;
      }
      if(this.servicetype == ''){
        this.$message({
          type:'error',
          message:'请选择业务类型'
        })
        return;
      }
      this.$axios({
        url: this.HOST + "/admin/jiameng/getRecord",
        method: "post",
        data: {
          uuid: this.$route.params.id,
          page: this.page,
          pagesize: this.pagesize,
          timerange: this.timerange,
          type: this.type,
          servicetype:this.servicetype
        }
      }).then(res => {
        this.changeType = false;
        if (res.data.status == 1) {
          if (res.data.total) {
            this.totalmoney = res.data.total;
          }
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    getdate(str) {
      let date = new Date();
      if (str == "start") {
        return (
          date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + "01 00:00:00"
        );
      } else {
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " 23:59:59"
        );
      }
    },
  },
  created: function() {

  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>